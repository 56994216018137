import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {SecureLocalstorageUtils} from "../../core/utils/secure-localstorage.utils";

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrl: './landing.component.css',
  standalone: true
})
export class LandingComponent implements OnInit {
  constructor(
    private router: Router, private secureLocalStorage: SecureLocalstorageUtils) {
  }

  public isLoggedIn(): boolean {
    return !!this.secureLocalStorage.getItem('token')
  }

  public async goTo(root: string) {
    await this.router.navigate([root])
  }

  async ngOnInit() {
    if (this.isLoggedIn()) await this.goTo('dashboard')
  }

}
