<div class="d-flex container-md w-100 p-4">
  <img (click)="goTo('')" class="me-5 unselectable" src="../../../assets/logo.png"
       style="height: auto!important; width: 150px!important; cursor:pointer">
  <nav *ngIf="currentRoute!='sign-in'  && currentRoute!='faq' &&isLoggedIn()"
       class="w-100 d-flex flex-nowrap navbar unselectable navbar-light">
    <div *ngFor="let title of titles; let i=index"
         class="fw-semibold me-5 w-100 text-center y-auto purple d-md-block d-none"
         style="white-space: nowrap!important">
      <span (click)="goTo('dashboard/'+roots[i])" [ngClass]="roots[i] != currentRoute2?'':'selected'" style="cursor: pointer"> {{ title }} </span>
    </div>
    <div class="nav-item dropdown  d-flex flex-nowrap m-auto p-3 rounded-4" *ngIf="isLoggedIn()"
         style="width:250px">
      <a aria-expanded="false" class="nav-link mobile hover" data-bs-toggle="dropdown"
         role="button">
          <div style="white-space: nowrap!important;" class="fw-semibold purple">{{ company?.name }}</div>
          <div style="font-size: 12px" class="opacity-50">{{ company?.tin }}</div>
      </a>
      <ul class="dropdown-menu left-product" style="z-index: 999999">
        <li>
          <a class="dropdown-item d-flex" href="mailto:supporto@paydays.it">
            <i class="ph-duotone font25 me-2 purple ph-lifebuoy"></i>
            <span class="my-auto" i18n>Supporto</span>
          </a>
        </li>
        <hr>
        <li>
          <a class="dropdown-item d-flex" href="https://share.hsforms.com/1lpqGac4NRHqJ0UhwsvvRnQboc9v" target="_blank">
            <i class="ph-duotone font25 me-2 purple ph-envelope"></i>
            <span class="my-auto" i18n>Contattaci</span>
          </a>
        </li>
        <hr>
        <!--li>
          <a class="dropdown-item d-flex" href="https://flowpay.it/privacy-policy" target="_blank">
            <i class="ph-duotone font25 me-2 purple ph-files"></i>
            <span class="my-auto" i18n>Trasparenza e privacy</span>
          </a>
        </li>
        <hr-->
        <li>
          <a class="dropdown-item d-flex" (click)="disconnect()">
            <i class="ph-duotone font25 me-2 purple ph-sign-out"></i>
            <span class="my-auto" i18n>Esci</span>
          </a>
        </li>
      </ul>
    </div>
  </nav>
  <button (click)="goTo('sign-in')" *ngIf="currentRoute!='sign-in'&&!isLoggedIn()"
          class="btn btn-primary my-auto ms-auto">Accedi
  </button>
  <a *ngIf="currentRoute==='sign-in'" href="https://share.hsforms.com/1lpqGac4NRHqJ0UhwsvvRnQboc9v"
     class="text-decoration-none my-auto ms-auto" target="_blank">
    <button class="btn btn-primary my-auto ms-auto">Contattaci</button>
  </a>
</div>
<hr class="shaded-hr hr-purple">
