<div [ngClass]="getBackgroundClass()"></div>
<div class="vh-100">
  <div class="h-100 d-flex flex-column">
    <app-navbar></app-navbar>
    <div class="container-md">
      <router-outlet></router-outlet>
    </div>
    <app-footer class="mt-auto"></app-footer>
  </div>

</div>


