import {Component, OnInit} from '@angular/core';
import {Router, RouterOutlet} from "@angular/router";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NgClass, NgIf} from "@angular/common";
import {environment} from "../../environments/environment";
import {SecureLocalstorageUtils} from "../core/utils/secure-localstorage.utils";

@Component({
  selector: 'app-sign-in',
  standalone: true,
  imports: [
    RouterOutlet,
    NgIf,
    ReactiveFormsModule,
    FormsModule,
    NgClass,
  ],
  templateUrl: './sign-in.component.html',
  styleUrl: './sign-in.component.css'
})

export class SignInComponent implements OnInit {
  public pp = false
  public tc = false

  constructor(private router: Router, private secureLocalStorage: SecureLocalstorageUtils) {
  }

  public isLoggedIn(): boolean {
    return !!this.secureLocalStorage.getItem('token')
  }


  async ngOnInit() {
    if (this.isLoggedIn()) await this.router.navigate(['dashboard'])
  }

  public goTo() {
    window.location.href = environment.API_BASE_URL + "/users/login"
  }
}
