import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {Observable} from "rxjs";
import {Injectable} from "@angular/core";
import {SecureLocalstorageUtils} from "../utils/secure-localstorage.utils";

@Injectable({
  providedIn: 'root',
})
export class ApiRequestInterceptor implements HttpInterceptor {

  constructor(
    private secureStorage: SecureLocalstorageUtils
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let jwt = this.secureStorage.getItem('token')
    let httpHeaders = request.headers;
    if (jwt) httpHeaders = httpHeaders.set('Authorization', 'Bearer ' + jwt);

    const cloned = request.clone({headers: httpHeaders});
    return next.handle(cloned);
  }
}
