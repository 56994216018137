import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router, RouterLink, RouterModule, RouterOutlet} from '@angular/router';
import {filter} from 'rxjs';
import {CommonModule} from '@angular/common';
import {FooterComponent} from "./shared/footer/footer.component";
import {NavbarComponent} from "./shared/navbar/navbar.component";
import {LandingComponent} from "./component/landing/landing.component";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  imports: [
    RouterModule,
    RouterLink,
    RouterOutlet,
    CommonModule,
    FooterComponent,
    NavbarComponent,
    LandingComponent
  ],
  standalone: true
})
export class AppComponent implements OnInit {
  public currentRoute: string = '';
  public currentRoute2: string = '';

  constructor(private router: Router, private route: ActivatedRoute) {
  }


  async ngOnInit() {
//    if (this.token) await this.router.navigateByUrl('https://paydays.it/dashboard/');
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) window.scrollTo(0, 0);
    });
    this.router.events.pipe(
      filter((event): event is NavigationEnd => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      const url = event.urlAfterRedirects; // Migliore gestione degli URL con redirect
      const parts = url.split('/').filter(part => part); // Filtra eventuali stringhe vuote
      this.currentRoute = parts.length > 0 ? parts[0] : '';
      this.currentRoute2 = parts.length > 1 ? parts[1] : '';
    });
  }

  getBackgroundClass(): string {
    if (this.currentRoute === 'sign-in') return 'background vh-100';
    else if (this.currentRoute === 'faq') return 'background';
    else if (this.currentRoute === 'dashboard' && this.currentRoute2Check()) return 'pink-bg';
    return '';
  }

  public currentRoute2Check(): boolean {
    const excludedSubRoutes = ['pay', 'wages', 'teams', 'setting'];
    return !excludedSubRoutes.includes(this.currentRoute2);
  }
}
