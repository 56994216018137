<div class="d-flex w-100 flex-column inter">
  <div class="d-flex custom-flex w-100">
    <div class="d-flex flex-column w-100 mt-3 me-md-3 ">

      <div class="box text-white w-100 p-2 lightgreen-bg">
        <div class="font40 my-1 my-md-5 purple fw-semibold text-md-center">Perché conviene <span
          class="yellow">paydays</span>?
        </div>
      </div>

      <div class="box mt-3 d-flex flex-column me-md-2 w-100 h-100 font32 yellow purple-bg">
        <div class="d-flex flex-nowrap p-3">
          <div class="ms-2 me-2 me-md-4">•</div>
          <div>
            <span class="fw-semibold me-1">un bonifico complessivo</span>
            significa pagare
            <span class="fw-semibold mx-1">una sola commissione alla banca</span>!
          </div>
        </div>
        <div class="d-flex flex-nowrap p-3 mt-auto">
          <div class="ms-2 me-2 me-md-4">•</div>
          <div>
            appena riceviamo il tuo bonifico, i tuoi collaboratori ricevono il proprio compenso via
            <span class="fw-semibold ms-1">bonifico istantaneo</span>.
          </div>
        </div>
      </div>
    </div>
    <div class="box mt-3 d-flex flex-column w-100 h-100 purple yellow-bg d-flex font35">
      <i class="ph-duotone ph-hourglass-high mx-auto font75 p-3"></i>
      <span class="fw-semibold my-2 my-md-4">Risparmio di tempo e zero errori di digitazione.</span>
      <span class="mt-4 mt-md-0 p-md-4">Per non considerare il risparmio di tempo solitamente speso per digitare manualmente i dati per effettuare i singoli bonifici e gli eventuali errori che si possono commettere (e ai quali, ahimè, si deve rimediare!)</span>

    </div>
  </div>

  <div class="d-flex flex-column lightgreen-bg my-3 w-100 box font20 purple">
    <div class="text-uppercase d-flex flex-nowrap fw-semibold font40 mb-4">
      <div class="me-2"> 🤯</div>
      Pagamento degli stipendi manuale
    </div>
    <table style="border-collapse: collapse; width: 100%;">
      <tr>
        <td style="border: none;">
          <div class="fw-semibold purple text-center yellow-bg salary rounded-3 p-3">Stipendio 1</div>
        </td>
        <td style="border: none;" class="d-none d-md-block">
          <i class="ph-duotone ph-arrow-circle-right p-4 d-none d-md-block yellow" style="font-size:55px"></i>
        </td>
        <td style="border: none;">
          <div class="p-4 fw-semibold purple font25">
            <span class="font20">Bonifico 2</span> - Commissione dovuta alla banca € 2,00 circa
          </div>
        </td>
      </tr>
      <tr>
        <td style="border: none;">
          <div class="fw-semibold purple text-center yellow-bg rounded-3 p-3 salary">Stipendio 2</div>
        </td>
        <td style="border: none;" class="d-none d-md-block">
          <i class="ph-duotone ph-arrow-circle-right p-4 d-none d-md-block yellow" style="font-size:55px"></i>
        </td>
        <td style="border: none;">
          <div class="p-4 fw-semibold purple font25">
            <span class="font20">Bonifico 2</span> - Commissione dovuta alla banca € 2,00 circa
          </div>
        </td>
      </tr>
      <tr>
        <td style="border: none;">
          <div class="fw-semibold text-center purple yellow-bg salary rounded-3 p-3"> Stipendio 3</div>
        </td>
        <td style="border: none;" class="d-none d-md-block">
          <i class="ph-duotone ph-arrow-circle-right p-4 d-none d-md-block yellow" style="font-size:55px"></i>
        </td>
        <td style="border: none;">
          <div class="p-4 fw-semibold purple font25">
            <span class="font20">Bonifico 2</span> - Commissione dovuta alla banca € 2,00 circa
          </div>
        </td>
      </tr>
    </table>
  </div>

  <div class="d-flex flex-column purple-bg my-3 w-100 box font20 yellow">
    <div class="text-uppercase d-flex flex-nowrap fw-semibold font40 mb-4">
      <div class="me-2"> 😎</div>
      Pagamento degli stipendi con paydays
    </div>
    <div class="d-flex flex-column mobile">
      <div class="d-flex flex-nowrap purple justify-content-around">
        <div class="fw-semibold text-center yellow-bg rounded-3 p-1">Stipendio 1</div>
        <div class="fw-semibold text-center yellow-bg rounded-3 mx-1 p-1">Stipendio 2</div>
        <div class="fw-semibold text-center yellow-bg rounded-3 p-1">Stipendio 3</div>
      </div>
      <hr class="my-4" style="border: 2px solid #FEFC54; opacity: 1">
      <div class="d-flex flex-nowrap">
        <i class="ph-duotone mx-auto ph-arrow-circle-down p-2 yellow" style="font-size:55px"></i>
      </div>
      <div class="mx-4  my-auto">
        <div class="fw-semibold text-center">Un solo bonifico <br> commissione dovuta alla banca <br>€ 2,00 circa</div>
      </div>
      <hr class="my-4" style="border: 2px solid #FEFC54; opacity: 1">
      <div class="my-auto">
        <div class="font20 text-center fw-semibold">Non appena riceviamo il tuo bonifico complessivo, trasferiamo gli stipendi sui
          conti dei tuoi collaboratori con bonifici istantanei*
        </div>
        <div class="font15 text-center mt-3 fst-italic">*commissione dovuta a paydays<br>€ 0,50 max per ciascun collaboratore</div>
      </div>
      <div class="d-flex flex-nowrap justify-content-around">
        <i class="ph-duotone text-center mx-auto ph-arrow-circle-down p-2 yellow" style="font-size:55px"></i>
        <i class="ph-duotone text-center mx-auto ph-arrow-circle-down p-2 yellow" style="font-size:55px"></i>
        <i class="ph-duotone text-center mx-auto ph-arrow-circle-down p-2 yellow" style="font-size:55px"></i>
      </div>
      <div class="d-flex flex-nowrap justify-content-around">
        <img height="58" src="../../../assets/pircing/user1.png" width="63"/>
        <img height="58" src="../../../assets/pircing/user2.png" width="63"/>
        <img height="58" src="../../../assets/pircing/user3.png" width="63"/>
      </div>
    </div>


    <div class="d-flex custom-flex desktop">
      <div class="d-flex flex-column purple justify-content-around">
        <div class="fw-semibold text-center yellow-bg rounded-3 p-4" style="width: 20rem!important;">Stipendio 1</div>
        <div class="fw-semibold text-center yellow-bg rounded-3 p-4 my-4">Stipendio 2</div>
        <div class="fw-semibold text-center yellow-bg rounded-3 p-4">Stipendio 3</div>
      </div>
      <hr class="mx-4  my-auto"
          style="border: none; border-left: 4px solid #FEFC54; height: 280px;background-color: #FEFC54;opacity: 1;">
      <i class="ph-duotone my-auto ph-arrow-circle-right yellow" style="font-size:55px"></i>
      <div class="mx-4  my-auto">
        <div class="fw-semibold mb-4 text-nowrap" style="font-size: 25px">Un solo bonifico</div>
        <div class="fw-semibold">commissione dovuta alla banca € 2,00 circa</div>
      </div>
      <hr class="mx-4 my-auto"
          style="border: none; border-left: 4px solid #FEFC54; height: 280px;background-color: #FEFC54;opacity: 1;">
      <div class="my-auto">
        <div class="font20 fw-semibold">Non appena riceviamo il tuo bonifico complessivo, trasferiamo gli stipendi sui
          conti dei tuoi collaboratori con bonifici istantanei*
        </div>
        <div class="font15 fst-italic">*commissione dovuta a paydays € 0,50 max per ciascun collaboratore</div>
      </div>
      <div class="d-flex justify-content-around flex-column ">
        <i class="ph-duotone ph-arrow-circle-right p-4 yellow" style="font-size:55px"></i>
        <i class="ph-duotone ph-arrow-circle-right p-4 yellow" style="font-size:55px"></i>
        <i class="ph-duotone ph-arrow-circle-right p-4 yellow" style="font-size:55px"></i>
      </div>
      <div class="d-flex flex-column justify-content-around me-md-5">
        <img height="58" src="../../../assets/pircing/user1.png" width="63"/>
        <img height="58" src="../../../assets/pircing/user2.png" width="63"/>
        <img height="58" src="../../../assets/pircing/user3.png" width="63"/>
      </div>
    </div>
  </div>

</div>
