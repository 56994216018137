import {Component, OnInit} from '@angular/core';
import {ReactiveFormsModule} from "@angular/forms";
import {NgForOf} from "@angular/common";

@Component({
  selector: 'app-faq',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    NgForOf
  ],
  templateUrl: './faq.component.html',
  styleUrl: './faq.component.css'
})
export class FaqComponent implements OnInit {
  public faqs: string[] = [
    "Di cosa ho bisogno per iniziare ad usare paydays?",
    "Paydays funziona solo con i cedolini?",
    "In quali formati posso caricare le buste paga?",
    "Sono già iscritto a FlowPay, perché devo creare un nuovo account?",
    "Che rapporto c’è da FlowPay e paydays?",
    "Perché devo iscrivermi a FlowPay per usare paydays?",
    "Ho bisogno di supporto, a chi posso rivolgermi?",
    "Il mio dipendente ha cambiato IBAN, cosa devo fare?",
    "Il pagamento non è andato a buon fine, cosa devo fare?",
    "Come posso utilizzare il servizio di pagamento massivo degli stipendi?",
    "Posso caricare cedolini protetti da password?"
  ]

  public answers: string[] = [
    "Per completare l’onboarding devi essere il rappresentante legale dell’azienda. Questo perché dovrai verificare la tua identità tramite SPID o videoselfie.",
    "Al momento paydays funziona solo con i cedolini, ma presto avrai la possibilità di caricare anche le fatture dei collaboratori esterni.",
    "Le buste paga possono essere caricate in formato .pdf o .zip.",
    "Per abilitare il tuo account FlowPay all’utilizzo di paydays abbiamo bisogno di verificare la tua identità. Impiegherai pochi minuti!",
    "FlowPay è l’istituto di pagamento che ha sviluppato paydays.",
    "Abbiamo bisogno di verificare la tua identità per fornirti un servizio sicuro. FlowPay è l’istituto di pagamento che inizializzerà per tuo conto i pagamenti degli stipendi e monitorerà gli esiti per permetterti di beneficiare della riconciliazione automatica. Tutto questo grazie alla tecnologia open banking – vuoi saperne di più? <a href=\"https://www.flowpay.it/products/open-banking\" target=\'_blank\'>Clicca qui.",
    "Scrivi all’indirizzo mail dedicato alle richieste di supporto paydays: <a href=\"mailto:supporto@paydays.it\" target=\"_blank\">supporto@paydays.it</a>",
    "Seleziona il nome del dipendente dalla lista delle anagrafiche del tuo team; da questa schermata potrai modificare i dati di contatto e l’IBAN del collaboratore. Ricordati di inserire sempre un IBAN di cui il collaboratore ha la titolarità.",
    "Se il pagamento dello stipendio non va a buon fine, riceverai una mail da parte di paydays e FlowPay che ti chiederà di tornare in piattaforma e controllare i dati del collaboratore. Potrai sempre contattarci per ricevere supporto, scrivendo a <a href=\"mailto:supporto@paydays.it\" target=\"_blank\">supporto@paydays.it</a>.",
    "La soluzione può essere utilizzata sia attraverso la piattaforma paydays che integrando il servizio via API nei sistemi aziendali.",
    "Assicurati che i documenti che caricherai su paydays NON siano protetti da password; per rimuoverla puoi utilizzare Adobe Acrobat o un qualsiasi tool online gratuito"
  ]

  ngOnInit(): void {

  }
}
