import { Component } from '@angular/core';
import {Router} from "@angular/router";

@Component({
  selector: 'app-pricing',
  standalone: true,
  imports: [],
  templateUrl: './pricing.component.html',
  styleUrl: './pricing.component.css'
})
export class PricingComponent {
  constructor(private router: Router) {
  }

  public async goTo(root: string){
    await this.router.navigate([root])
  }
}
