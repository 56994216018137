<div class="d-flex w-100 flex-column inter">
  <div class="d-flex custom-flex w-100">
    <div class="d-flex flex-column w-100 mt-3 me-md-3 ">
      <div class="box text-white w-100 main-title" style="background: #8455EA;">
        <div class="font65 inter yellow mb-md-5 mb-md-4" style="line-height: 1; font-weight: 700;">
          Paga tutto il team con un click
        </div>
        <div style="height: 5rem" class="d-md-block d-none"></div>
        <div class="font22 mt-5 mt-md-0">
          Scopri il modo più <b>facile</b> e <b>sicuro</b> di pagare gli stipendi con un <b>unico bonifico</b> senza
          digitare manualmente le informazioni.
        </div>
      </div>
      <div class="box mt-3 d-flex flex-nowrap me-md-2 w-100 font32 purple lightgreen-bg"
           style="padding-top: 2rem!important; padding-bottom: 2rem!important; cursor: pointer"
           (click)="goTo('sign-in')">
        <i class="ph-duotone my-auto font125 me-3 ph-arrow-line-up-right"></i>
        <div class="my-auto fw-bold zoom">
          Registrati ora e<br>
          diventa beta tester!
        </div>
      </div>
    </div>
    <div class="box-pc mt-3 w-100 yellow-bg d-none d-md-block" style="position: relative">
      <img class="img-fluid monitor" src="../../assets/landing/the-real-monitor-updated.png">
      <img class="img-fluid monitor-mobile d-none" src="../../assets/landing/the-real-monitor-updated.png">
      <div style="height: 8rem"></div>
      <div class="box d-flex flex-nowrap mx-auto mt-auto purple lightgreen-bg" (click)="goTo('pricing')"
           style="width: 70%!important; cursor: pointer; padding: 1.5rem!important; font-size: 30px">
        <div class="m-auto zoom fw-bold">Scopri perché<br> paydays<br>conviene</div>
        <i class="ph-duotone my-auto font125 ph-arrow-line-up-right"></i>
      </div>
      <br>
      <br>
    </div>
  </div>

  <div class="d-flex custom-flex my-3 w-100 box justify-content-around font20 yellow justify-content-around"
       style="background: #8455EA; padding: 2rem!important; padding-bottom: 1rem!important;">
    <div class="d-flex flex-column mb-3">
      <div class="fw-semibold mb-3">Carica le buste paga</div>
      <div class="font15 text-white">
        Fai l’upload dei cedolini in formato <br>.pdf o .zip e attendi il caricamento <br>delle informazioni.
      </div>
    </div>
    <i class="ph-duotone ph-arrow-elbow-right  yellow my-auto mx-auto mx-md-0" style="font-size:85px"></i>
    <div class="d-flex flex-column mb-3 ">
      <div class="fw-semibold mb-3"> Controlla i dati estratti</div>
      <div class="font15 text-white">
        Verifica e completa l’anagrafica dei<br>collaboratori, gli IBAN di addebito e gli <br>importi da pagare.
      </div>
    </div>
    <i class="ph-duotone ph-arrow-elbow-right  yellow my-auto mx-auto mx-md-0" style="font-size:85px"></i>
    <div class="d-flex flex-column mb-3 mx-auto mx-md-0">
      <div class="fw-semibold mb-3"> Paga gli stipendi con un unico bonifico</div>
      <div class="font15 text-white">
        Seleziona i cedolini da pagare ed esegui un
        <a class="text-white" href="https://www.flowpay.it/products/bulk-payment" target="_blank">bonifico
          <br class="d-none d-md-block"> della somma complessiva</a>, paydays invierà ad <br class="d-none d-md-block">
        ogni collaboratore lo stipendio e la relativa busta
        paga.
      </div>
    </div>
  </div>

  <div class="box d-flex flex-nowrap d-md-none mb-4 font30 purple lightgreen-bg" (click)="goTo('pricing')"
       style="cursor: pointer">
    <div class="m-auto zoom fw-bold">Scopri perché<br>paydays<br>conviene</div>
    <i class="ph-duotone my-auto font125 ph-arrow-line-up-right"></i>
  </div>


  <div class="d-flex custom-flex h-100 w-100">
    <div class="box yellow-bg me-md-3 d-flex flex-column" style="padding-left: 1.5rem!important; padding-right: 1.5rem!important">
      <div class="d-flex flex-nowrap purple my-4 my-md-auto">
        <i class="ph-duotone ph-list-bullets pe-4 my-auto" style="font-size: 70px"></i>
        <div class="ps-3 my-auto font30" style="border-left: 5px solid #8455EA">
          Addio all’inserimento manuale delle informazioni di pagamento
        </div>
      </div>

      <div class="d-flex flex-nowrap purple my-4 my-md-auto">
        <i class="ph-duotone ph-check-square pe-4 my-auto" style="font-size: 70px"></i>
        <div class="ps-3 my-auto font30" style="border-left: 5px solid #8455EA">
          L’IBAN del collaboratore viene verificato grazie al
          <a class="fw-semibold purple" target="_blank" href="https://www.flowpay.it/products/check-iban">servizio Check
            IBAN</a>
        </div>
      </div>

      <div class="d-flex flex-nowrap purple my-4 my-md-auto">
        <i class="ph-duotone ph-paper-plane-tilt pe-4 my-auto" style="font-size: 70px"></i>
        <div class="ps-3 my-auto font30" style="border-left: 5px solid #8455EA">
          Esegui un <b>unico bonifico </b> dell’importo complessivo degli stipendi
        </div>
      </div>

      <div class="d-flex flex-nowrap purple my-4 my-md-auto">
        <i class="ph-duotone ph-bank pe-4 my-auto" style="font-size: 70px"></i>
        <div class="ps-3 my-auto font30" style="border-left: 5px solid #8455EA">
          L’<a class="purple" href="https://www.flowpay.it/products/open-banking" target="_blank">open banking</a> garantisce
          la <b>massima sicurezza</b> delle operazioni grazie alla
          <a class="purple" href="https://www.flowpay.it/who-we-are/faq" target="_blank">Strong Customer
            Authentication</a>
        </div>
      </div>
    </div>

    <div class="d-flex flex-column">
      <div class="box d-flex flex-column mt-flex h-100 text-white font35" style="background: #8455EA">
        <div class="p-md-5">
          <b>paydays</b> è stato sviluppato da <b>FlowPay</b>, istituto di pagamento con tecnologia open
          banking che supporta le aziende nell’efficientare i flussi di incasso e pagamento.
        </div>
        <a href="https://www.flowpay.it" target="_blank"
           class="d-flex flex-nowrap mt-md-auto mt-4 mx-auto yellow p-3 p-md-5"
           style="text-decoration: none!important;cursor: pointer">
          <i class="ph-duotone my-auto font125 me-3 ph-arrow-line-up-right"></i>
          <div class="my-auto zoom font50">
            <b>Scopri di più sul <br class="d-none d-md-block">magico mondo<br class="d-none d-md-block"> FlowPay</b>
          </div>
        </a>
      </div>
      <div style="text-decoration: none" class="d-flex custom-flex mt-3">
        <a class="box d-flex w-100 flex-column font45 fw-semibold purple lightgreen-bg"
           href="https://share.hsforms.com/1lpqGac4NRHqJ0UhwsvvRnQboc9v" target="_blank"
           style="cursor: pointer; text-decoration: none!important;">
          <div class="m-auto p-3 p-md-5 zoom">CONTATTACI</div>
        </a>
        <div class="box mt-flex ms-md-3 d-flex flex-column yellow" style="background: #8455EA; cursor: pointer"
             (click)="goTo('faq')">
          <div class="fw-semibold p-3 p-md-5 mx-auto font45 my-auto zoom">FAQs</div>
        </div>
      </div>
    </div>

  </div>
</div>
