import { ResolveFn } from "@angular/router";
import { inject } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { CompanyService } from "../services/company.service";
import { CompanyModel } from "../models/company.model"; // Importa CompanyModel

export const dashboardResolver: ResolveFn<CompanyModel> = async () => {
  const company = inject(CompanyService);
  const spinner = inject(NgxSpinnerService);
  try {
    const companyData = await company.get();
    await spinner.hide('appRoot');
    return companyData;
  } catch (e) {
    window.alert("Non è stato possibile recuperare l'azienda, prova a ricaricare la pagina.");
    await spinner.hide('appRoot');
    throw e;
  }
};
