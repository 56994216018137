import {Routes} from '@angular/router';
import {LandingComponent} from "./component/landing/landing.component";
import {SignInComponent} from "./sign-in/sign-in.component";
import {NotFoundComponent} from "./shared/not-found/not-found.component";
import {FaqComponent} from "./component/faq/faq.component";
import {PricingComponent} from "./component/pricing/pricing.component";
import {dashboardGuard} from "./core/guards/dashboard.guard";
import {dashboardResolver} from "./core/resolvers/dashboard.resolver";

export const routes: Routes = [
  {
    path: '',
    component: LandingComponent
  },
  {
    path: "dashboard",
    canActivate: [dashboardGuard],
  resolve: [dashboardResolver],
    loadChildren: () => import(`./dashboard/dashboard.routes`)
  },
  {
    path: "sign-in",
    component: SignInComponent,
  },
  {
    path: "faq",
    component: FaqComponent,
  },
  {
    path: "pricing",
    component: PricingComponent,
  },
  {
    path: "not-found",
    component: NotFoundComponent,
  },
  {
    path: "**",
    redirectTo: "dashboard",
  }
];
