import {Component, OnInit} from '@angular/core';
import {filter} from "rxjs";
import {NavigationEnd, Router} from "@angular/router";
import {NgClass, NgIf} from "@angular/common";
import {FormsModule} from "@angular/forms";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.css',
  imports: [
    NgClass,
    FormsModule,
    NgIf
  ],
  standalone: true
})
export class FooterComponent implements OnInit{
  public currentRoute=""
  public currentRoute2?: string
  constructor(private router: Router) {
  }
  ngOnInit(): void {
    this.router.events.pipe(
      filter((event): event is NavigationEnd => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      const url = event.url;
      const parts = url.split('/');
      this.currentRoute = parts.length > 1 ? parts[1] : '';
      this.currentRoute2 = parts.length > 1 ? parts[2] : '';
    });
  }
}
