import {Injectable} from "@angular/core";
import {environment} from "../../../environments/environment";

export enum LogLevel {
  debug = 0,
  info = 1,
  warn = 2,
  error = 3
}

@Injectable({providedIn: 'root'})
export class LoggerUtils {

  level: LogLevel = LogLevel.debug;

  constructor() {
  }

  info(args: string | Object): void {
    if (environment.production || this.level > LogLevel.info) return;
    console.info(args);
  }

  log(args: string | Object): void {
    if (environment.production) return;
    console.log(args);
  }

  warn(args: string | Object): void {
    if (environment.production || this.level > LogLevel.warn) return;
    console.warn(args);
  }

  error(args: string | Object): void {
    if (environment.production || this.level > LogLevel.error) return;
    console.error(args);
  }

  debug(args: string | Object): void {
    if (environment.production) return;
    console.debug(args);
  }
}

