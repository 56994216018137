import {ActivatedRoute, ActivatedRouteSnapshot, CanActivateFn} from "@angular/router";
import {inject} from "@angular/core";
import {CompanyService} from "../services/company.service";
import {SecureLocalstorageUtils} from "../utils/secure-localstorage.utils";
import {LoggerUtils} from "../utils/logger.utils";

export const dashboardGuard: CanActivateFn = async (route: ActivatedRouteSnapshot) => {
  const secureStorage = inject(SecureLocalstorageUtils)
  const companyService = inject(CompanyService)
  const logger = inject(LoggerUtils)

  const token = route.queryParamMap.get('token')
  if (token) secureStorage.setItem('token', token)
  try {
    await companyService.get()
    return true
  } catch (error) {
    logger.debug("Clear storage")
    secureStorage.clear()
    window.location.href = 'https://paydays.it/sign-in';
    return false
  }
}
