<div class=" d-flex flex-wrap my-5">
  <div class="d-flex flex-column mx-auto box purple-bg">
    <div class="font85 fw-semibold mx-auto" i18n>Ciao!</div>
    <div>
      Stai per atterrare sul processo di identificazione di <b>FlowPay</b>, l'istituto di pagamento che ha sviluppato
      paydays.
    </div>
    <div class="my-5 fw-semibold" i18n>👉🏻 Perché?</div>
    <div i18n>
      Abbiamo bisogno di verificare la tua identità così da fornirti un servizio sicuro. Per completare il tuo account
      ti basterà identificarti tramite SPID o video selfie e fornirci i consensi per supportarti nelle operazioni di
      pagamento.
    </div>
    <div class="mt-4">
      Facile no?
    </div>
    <div class="d-flex flex-column my-5">
      <label class="pday-checkbox">
        <input class="me-2" type="checkbox" [(ngModel)]="pp">
        <span class="checkmark"></span>
        <div class="text-white" i18n>Dichiaro di aver preso visione della
          <a class="yellow" href="https://flowpay.it/privacy-policy" target="_blank">Privacy Policy</a>
          di paydays</div>
      </label>
      <label class="pday-checkbox">
        <input class="me-2" type="checkbox" [(ngModel)]="tc">
        <span class="checkmark"></span>
        <div class="text-white" i18n>Dichiaro di accettare
          <a class="yellow" href="https://flowpay.s3.eu-west-3.amazonaws.com/068DE696-B47D-4B55-A258-9EC6F41C8E33.pdf" target="_blank">Termini e Condizioni</a>
          di paydays.</div>
      </label>
    </div>
    <button class="btn btn-outline-secondary mx-auto" (click)="goTo()" [disabled]="!pp||!tc">Prosegui su FlowPay</button>
  </div>
</div>
