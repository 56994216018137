import {inject, Injectable, PLATFORM_ID} from "@angular/core";
import {isPlatformServer} from "@angular/common";

@Injectable({providedIn: 'root'})
export class SecureLocalstorageUtils {
  private readonly platformId = inject(PLATFORM_ID);

  constructor() {
  }

  public getItem<T>(key: string): T | null {
    if (isPlatformServer(this.platformId)) return null;
    let data: any = localStorage.getItem(key);
    if (!data) return null;

    try {
      return <T>JSON.parse(data);
    } catch (error) {
      return null;
    }
  }

  public setItem<T>(key: string, value: T): void {
    if (isPlatformServer(this.platformId)) return;
    localStorage.setItem(key, JSON.stringify(value))
  }

  public clear(): void {
    if (isPlatformServer(this.platformId)) return;
    localStorage.clear()
  }

  public async removeItem(key: string): Promise<void> {
    if (isPlatformServer(this.platformId)) return;
    localStorage.removeItem(key)
  }
}
