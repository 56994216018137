<div class="container-md d-flex flex-wrap">
  <div class="mx-auto my-5">
    <div class="d-flex flex-nowrap">
      <div class="box-arrow d-flex flex-wrap">
        <i class="ph-duotone ph-arrow-down-right yellow my-auto mx-auto"></i>
      </div>
      <div class="purple font55 fw-semibold ms-4" i18n style="line-height: 60px">
        Il payday è arrivato, <br>torna alla pagina <br>iniziale!
      </div>
    </div>
    <div class="box-404 mt-4 d-flex flex-wrap">
      <div class="fw-semibold mx-auto my-auto">404</div>
    </div>
  </div>
</div>
