<div class=" d-flex flex-wrap my-5">
  <div class="d-flex flex-column mx-auto box purple-bg">
    <div class="fw-semibold mx-auto" style="font-size:35px" i18n>FAQs</div>
    <div class="accordion" id="accordionFAQ">
      <div *ngFor="let question of faqs; let i = index" class="accordion-item mt-5">
        <h2 class="accordion-header" [id]="'heading'+i">
          <button [attr.aria-controls]="'collapse' + i" [attr.data-bs-target]="'#collapse' + i" aria-expanded="false"
                  class="accordion-button collapsed font20 fw-semibold" data-bs-toggle="collapse" type="button"
                  data-bs-paren="'#accordionFAQ'">
           <span class="me-3"> {{ question }}</span>
          </button>
        </h2>
        <div [id]="'collapse' + i" class="accordion-collapse collapse" data-bs-parent="#accordionFAQ"
             [attr.aria-labelledby]="'heading'+1">
          <div class="accordion-body" [innerHTML]="answers[i]"></div>
        </div>
      </div>
    </div>
  </div>
</div>
