import {Component, OnInit} from '@angular/core';
import {NgClass, NgFor, NgForOf, NgIf, NgOptimizedImage} from "@angular/common";
import {NavigationEnd, Router} from "@angular/router";
import {filter} from "rxjs";
import {FormsModule} from "@angular/forms";
import {SecureLocalstorageUtils} from "../../core/utils/secure-localstorage.utils";
import {CompanyService} from "../../core/services/company.service";
import {CompanyModel} from "../../core/models/company.model";
import {SpinnerService} from "../../core/services/spinner.service";
import {NgxSpinnerModule} from "ngx-spinner";

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrl: './navbar.component.css',
  imports: [NgIf, NgFor, NgForOf, NgClass, FormsModule, NgOptimizedImage, NgxSpinnerModule],
  standalone: true
})
export class NavbarComponent implements OnInit {
  public currentRoute: string = '';
  public currentRoute2: string = '';
  public company?: CompanyModel
  public titles: string[] = ["Paga", "Storico stipendi", "Il tuo team", "Preferenze di pagamento",]
  public roots: string [] = ["pay", "wages", "teams", "setting"]

  constructor(private spinner: SpinnerService,
              private router: Router,
              private secureLocalStorage: SecureLocalstorageUtils,
              private companyService: CompanyService) {
    this.router.events.pipe(
      filter((event): event is NavigationEnd => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      const url = event.url;
      const parts = url.split('/');
      this.currentRoute = parts.length > 1 ? parts[1] : '';
      this.currentRoute2 = parts.length > 1 ? parts[2] : '';
    });
  }

  public isLoggedIn(): boolean {
    return !!this.secureLocalStorage.getItem('token')
  }

  public disconnect() {
    this.secureLocalStorage.clear()
    window.location.reload()
  }

  async ngOnInit() {
    this.company = await this.companyService.get()
    if (!this.company.name) window.location.reload()
  }

  public async goTo(root?: string) {
    if (root && root != 'dashboard/' + this.currentRoute2) await this.spinner.show('appRoot', `Init ${NavbarComponent.name}`)
    if (!root && this.isLoggedIn()) await this.router.navigate(['dashboard'])
    if (!root && !this.isLoggedIn()) await this.router.navigate([''])
    if (root) await this.router.navigate([root])
  }
}
